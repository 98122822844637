window.$ = window.jQuery = require("jquery");

import LazyLoad from "vanilla-lazyload";

require("jquery-viewport-checker");
require("simplelightbox");
require("./vendors/jquery.webticker.min");
require("bootstrap");
require("leaflet");
require("slick-carousel");
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./images-slider");
  require("./schedule");
  require("./repertoire");
  require("./onWidgetLoad");
  require("./player-history");
  require("./floating-player");
  require("./collapse");
  require("./current-title");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });

  $("#webTicker").webTicker({
    duplicate: true,
    rssfrequency: 0,
    startEmpty: false,
    hoverpause: false,
  });

  if ($(".slick-slide:not(.slick-cloned) .simplelightbox").length > 0) {
    $(".slick-slide:not(.slick-cloned) .simplelightbox").simpleLightbox();
  }
});
