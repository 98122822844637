$("#main-photo-slider").slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  adaptiveHeight: true,
  infinite: true,
  useTransform: true,
  speed: 400,
  asNavFor: "#other-photos-slider",
  cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
});

$("#other-photos-slider").slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  infinite: true,
  focusOnSelect: true,
  asNavFor: "#main-photo-slider",
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
});
