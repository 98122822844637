//get song title handle
const ticker = document.getElementById("ticker");

function getTitle() {
  fetch("https://stream.super.fm:8443/status-json.xsl", {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      const source = data.icestats.source;
      let title;

      if (Array.isArray(source)) {
        const findSource = source.find(
          (item) => item.server_name === "Radio Super FM"
        );
        title = findSource.title;
      } else {
        title = source.title;
      }

      if (ticker.textContent !== title || ticker.textContent !== "") {
        ticker.textContent = title;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// if (ticker) {
//   getTitle();
//   intervalID = setInterval(() => {
//     getTitle();
//   }, 5000);
// }

if (ticker) {
  getTitle();
}

//open player handle
const playerBar = document.getElementById("player-bar");
const volumeControl = document.getElementById("volume-control");
const playerLink = document.getElementById("player-link");
const muteButton = document.getElementById("player-mute");

if (playerLink) {
  playerLink.addEventListener("click", function(e) {
    e.preventDefault();
    window.open("/player", "Player", "height=660,width=580,resizable=0");
    window.resizeTo(580, 660);
  });
}

if (playerBar) {
  const playButton = document.getElementById("play-button");
  const audioStream = document.getElementById("audio-stream");

  //time counter handle

  const hoursLabel = document.getElementById("player-hours");
  const minutesLabel = document.getElementById("player-minutes");
  const secondsLabel = document.getElementById("player-seconds");
  let totalSeconds = 0;
  let totalMinutes = 0;
  let intervalId;

  function setTime() {
    ++totalSeconds;
    if (totalSeconds != 0 && !(totalSeconds % 60)) {
      totalMinutes++;
    }

    secondsLabel.innerHTML = pad(totalSeconds % 60);
    minutesLabel.innerHTML = pad(parseInt(totalMinutes % 60));
    hoursLabel.innerHTML = pad(parseInt(totalMinutes / 60));
  }

  function pad(val) {
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  // play & resume handle

  function play() {
    if (audioStream.paused) {
      audioStream.src =
        "https://stream.super.fm:8443/superfm.mp3?" + Date.now();
      const promise = audioStream.play();

      document
        .getElementById("icon-play")
        .classList.add("player__button--hidden");
      document
        .getElementById("icon-pause")
        .classList.remove("player__button--hidden");

      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            intervalId = setInterval(setTime, 1000);
          })
          .catch((error) => {
            // Autoplay not allowed!
            audioStream.pause();
            audioStream.removeAttribute("src");
            audioStream.load();
            document
              .getElementById("icon-play")
              .classList.remove("player__button--hidden");
            document
              .getElementById("icon-pause")
              .classList.add("player__button--hidden");
            clearInterval(intervalId);
            console.log("Autoplay in not allowed in your browser.");
          });
      }
    } else {
      audioStream.pause();
      audioStream.removeAttribute("src");
      audioStream.load();
      document
        .getElementById("icon-play")
        .classList.remove("player__button--hidden");
      document
        .getElementById("icon-pause")
        .classList.add("player__button--hidden");
      clearInterval(intervalId);
    }
  }

  //volume control

  if (volumeControl) {
    $(volumeControl)
      .on("input", function(e) {
        audioStream.volume = e.currentTarget.value / 100;

        var min = e.target.min,
          max = e.target.max,
          val = e.target.value;

        $(e.target).css({
          backgroundSize: ((val - min) * 100) / (max - min) + "% 100%",
        });
      })
      .trigger("input");
  }

  if (muteButton) {
    muteButton.addEventListener("click", function() {
      if (audioStream.volume !== 0) {
        audioStream.volume = 0;
        volumeControl.value = 0;
        $(volumeControl).trigger("input");
      } else {
        audioStream.volume = 1;
        volumeControl.value = 100;
        $(volumeControl).trigger("input");
      }
    });
  }

  playButton.addEventListener("click", () => {
    play();
  });

  $(playButton).trigger("click");
}

//link each baner to parent window

const playerLinks = [...document.getElementsByClassName("player-link")];

playerLinks.forEach(function(item) {
  item.addEventListener("click", function() {
    const itemHref = item.dataset.link;
    // window.opener.location.href = itemHref;
    window.open(`https://super.fm${itemHref}`, "_blank");
    // window.opener.location.reload(false);
  });
});
