const playerHistory = document.getElementById("player-history");

if (playerHistory) {
  const loading = document.getElementById("historyLoading");

  const getFormatedData = (array) => {
    const data = [];
    array.forEach((track) => {
      const trackDatas = [...track.childNodes];
      const trackData = {};

      trackDatas.forEach((item) => {
        trackData[item.nodeName] = item.innerHTML;
      });

      data.push(trackData);
    });

    return data;
  };

  const getHistory = (array) => {
    let data = [];
    array.forEach((track) => {
      const date = new Date(track.time * 1000);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const dataCopy = data;
      if (dataCopy.length) {
        const recordIndex = data.findIndex(
          (item) =>
            item.year === year &&
            item.month === month &&
            item.day === day &&
            item.hour === hour
        );

        if (recordIndex > -1) {
          dataCopy[recordIndex].songs.push(track);
        } else {
          const newRecord = {
            year,
            month,
            day,
            hour,
            songs: [track],
          };
          dataCopy.push(newRecord);
        }
      } else {
        const newRecord = {
          year,
          month,
          day,
          hour,
          songs: [track],
        };
        dataCopy.push(newRecord);
      }

      data = dataCopy;
    });

    return data;
  };

  const renderPagination = (array) => {
    const pagination = document.getElementById("trackPagination");
    const wrapper = document.getElementById("trackPaginationWrapper");
    let paginationResult = "";

    array
      .slice()
      .reverse()
      .forEach((item) => {
        const { year, month, day, hour } = item;

        paginationResult += `<li class="history__hour hour-data" data-time="${year}${month}${day}${hour}">${hour}:00</li>`;
      });

    pagination.innerHTML = paginationResult;

    $("#trackPagination").slick({
      slidesToShow: 5,
      slidesToScroll: 4,
      arrows: true,
      infinite: false,
      nextArrow: $("#pag-next"),
      prevArrow: $("#pag-prev"),
      rtl: true,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
      ],
    });

    $("#trackPagination").on("afterChange", function(
      event,
      slick,
      currentSlide
    ) {
      const breakPoint = slick.activeBreakpoint;
      let target;
      const prev = document.getElementById("pag-prev");

      if (breakPoint === 1500) {
        target = slick.slideCount - 4;
        prev.dataset.slide = slick.slideCount - 4;
      } else if (breakPoint === 1200) {
        target = slick.slideCount - 5;
        prev.dataset.slide = slick.slideCount - 5;
      } else if (breakPoint === 992) {
        target = slick.slideCount - 4;
        prev.dataset.slide = slick.slideCount - 4;
      } else if (breakPoint === 576) {
        target = slick.slideCount - 3;
        prev.dataset.slide = slick.slideCount - 3;
      } else {
        target = slick.slideCount - 5;
        prev.dataset.slide = slick.slideCount - 5;
      }
      if (currentSlide === target) {
        $("#pag-next").click(function(e) {
          const slideno = $(this).data("slide");
          $("#trackPagination").slick("slickGoTo", slideno - 1);
        });
      } else {
        $("#pag-prev").click(function(e) {
          $("#trackPagination").slick("slickGoTo", target);
        });
      }
    });

    wrapper.classList.add("history__pagination-wrapper--loaded");
  };

  const renderHistory = (array) => {
    const wrapper = document.getElementById("trackResults");
    let historyResult = "";

    array
      .slice()
      .reverse()
      .forEach((item, index) => {
        let songsWrapper = "";
        let songs = "";

        const { year, month, day, hour } = item;

        const sortedSongs = item.songs.sort((a, b) => a.time - b.time);

        sortedSongs.forEach((song) => {
          const songDate = new Date(song.time * 1000);
          const songHour = songDate.getHours();
          const songMinutes = songDate.getMinutes();
          songs += `<li class="history__item"><span class="history__play-time">${
            songHour < 10 ? "0" : ""
          }${songHour}:${
            songMinutes < 10 ? "0" : ""
          }${songMinutes}</span><div class="history__track-content"><span class="history__artist">${
            song.artist
          }</span><span class="history__title">${song.title}</span></div></li>`;
        });

        songsWrapper = `<ul class="history__items songs-data" data-time="${year}${month}${day}${hour}">${songs}</ul>`;

        historyResult += songsWrapper;
      });

    wrapper.innerHTML = historyResult;
  };

  const selectHour = (selected) => {
    const hourData = [...document.getElementsByClassName("hour-data")];
    const songsData = [...document.getElementsByClassName("songs-data")];

    let tragetDate;

    if (selected) {
      tragetDate = selected;
    } else {
      const currDate = new Date();
      const currYear = currDate.getFullYear();
      const currMonth = currDate.getMonth() + 1;
      const currDay = currDate.getDate();
      const currHour = currDate.getHours();
      tragetDate = `${currYear}${currMonth}${currDay}${currHour}`;
    }
    hourData.forEach((item) => {
      const dataTime = item.dataset.time;
      if (dataTime === tragetDate) {
        item.classList.add("history__hour--active");
      } else {
        item.classList.remove("history__hour--active");
      }
    });

    songsData.forEach((item) => {
      const dataTime = item.dataset.time;
      if (dataTime === tragetDate) {
        item.classList.add("history__items--active");
      } else {
        item.classList.remove("history__items--active");
      }
    });
  };

  const checkContent = () => {
    const hourData = [...document.getElementsByClassName("hour-data")];
    const songsData = [...document.getElementsByClassName("songs-data")];

    if (!document.querySelector("history__hour--active")) {
      hourData[0].classList.add("history__hour--active");
    }

    if (!document.querySelector("history__items--active")) {
      songsData[0].classList.add("history__items--active");
    }
  };

  const setListener = () => {
    const hourData = [...document.getElementsByClassName("hour-data")];

    hourData.forEach((item) => {
      item.addEventListener("click", (e) => {
        selectHour(e.target.dataset.time);
      });
    });
  };

  const getData = async () => {
    loading.style.display = "block";
    const response = await fetch(
      "/storage/app/uploads/playlista/playlista.xml"
    );
    const str = await response.text();
    const data = new window.DOMParser().parseFromString(str, "text/xml");

    let tracks;
    if (data.activeElement) {
      tracks = [...data.activeElement.childNodes];
      // }
    } else if (data.getElementsByTagName("track")) {
      tracks = [...data.getElementsByTagName("track")];
    }

    const formatedData = getFormatedData(tracks);
    const history = getHistory(formatedData);

    loading.style.display = "none";
    renderPagination(history);
    renderHistory(history);
    selectHour();
    checkContent();
    setListener();
  };

  $("#trackPagination").on("init", function(event, slick) {
    const breakPoint = slick.activeBreakpoint;
    let target;
    const prev = document.getElementById("pag-prev");

    if (breakPoint === 1500) {
      prev.dataset.slide = 21;
    } else if (breakPoint === 1200) {
      prev.dataset.slide = 20;
    } else if (breakPoint === 992) {
      prev.dataset.slide = 21;
    } else if (breakPoint === 576) {
      prev.dataset.slide = 22;
    } else {
      prev.dataset.slide = 20;
    }

    prev.addEventListener("click", () => {
      $("#trackPagination").slick("slickGoTo", prev.dataset.slide);
    });
  });

  getData();
}
