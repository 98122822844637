//get current day handle
var days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
const date = new Date();
var currentday = days[date.getDay()];
var daybuttons = document.querySelectorAll(".day-link");

if (daybuttons) {
  [...daybuttons].forEach((element) => {
    if (element.dataset.day === currentday) {
      const pageTitle = document.getElementById("dayOfWeek");
      pageTitle.textContent = element.getAttribute("name");
      const dayTabs = document.querySelectorAll(`.${currentday}`);

      [...dayTabs].forEach((item) => {
        item.style.display = "block";
      });
      element.classList.add("active");
    }
  });
}

//Toggl days handle

function openDay(e, dayName) {
  // Declare all variables
  let i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("day-content");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.querySelectorAll(".day-link");

  [...tablinks].forEach((element) => {
    element.classList.remove("active");
  });

  // Show the current tab, and add an "active" class to the button that opened the tab
  const dayTabs = document.querySelectorAll(`.${dayName}`);

  [...dayTabs].forEach((item) => {
    item.style.display = "block";
  });
  e.target.className += " active";
  const pageTitle = document.getElementById("dayOfWeek");
  pageTitle.textContent = e.target.name;
}

const tablinks = document.querySelectorAll(".day-link");

[...tablinks].forEach((element) => {
  var day = element.dataset.day;
  element.addEventListener("click", function (e) {
    openDay(e, day);
  });
});
