const Amplitude = require("amplitudejs");

const isPodcastsPage = document.getElementById("podcasts");

if (isPodcastsPage) {
  $.request("podcasts::onGetPodcastsJson", {
    beforeSend: function() {
      $("#podcastsLoading").show();
    },
    success: function(data) {
      const obj = JSON.parse(data.result);

      function* values(obj) {
        for (let prop of Object.keys(obj)) yield obj[prop];
      }
      let podcasts = Array.from(values(obj));

      Amplitude.init({
        songs: podcasts,
        volume: 100,
      });

      Amplitude.pause();

      let audio_arr = [];
      podcasts.forEach((song, index) => {
        const audio = document.createElement("audio");
        audio.src = song.url;
        audio_arr.push(audio);
      });

      audio_arr.forEach((audio, index) => {
        audio.addEventListener("loadeddata", (e) => {
          const totalSeconds = Math.floor(e.currentTarget.duration);

          // const hours = pad(Math.floor(totalSeconds / 3600));
          const minutes = parseInt(Math.round(totalSeconds / 60));
          // const seconds = pad(parseInt(totalSeconds % 60));

          const totalTime = `${minutes} min`;

          // if (parseInt(hours)) {
          //   totalTime = `${hours}:${minutes}:${seconds}`;
          // } else {
          //   totalTime = `${minutes}:${seconds}`;
          // }

          document.querySelectorAll(".duration-minutes")[
            index
          ].innerHTML = totalTime;
        });
      });

      const durationField = document.getElementById("count-uration");
      if (durationField) {
      }

      const playBtn = [...document.getElementsByClassName("track-action")];

      playBtn.forEach(function(item) {
        item.addEventListener("click", function() {
          const player = document.getElementById("floating-player");
          if (!player.classList.contains("active")) {
            player.classList.add("active");
          }
        });
      });
    },
    complete: function() {
      $("#podcastsLoading").hide();
    },
  });
}
