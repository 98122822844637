const ticker = document.getElementById("ticker");

const getSongTitle = async () => {

    const response = await fetch(
      "/storage/app/uploads/playlista/playlista.xml"
    );
    const str = await response.text();
    const data = new window.DOMParser().parseFromString(str, "text/xml");

    let tracks;

    if (data.activeElement) {
      tracks = [...data.activeElement.childNodes];
    } else if (data.getElementsByTagName("track")) {
      tracks = [...data.getElementsByTagName("track")];
    }

    const formatedData = getFormatedData(tracks);
    const song = getCurrentSongTitle(formatedData.reverse());

    if (ticker.innerHTML != song) {

      ticker.innerHTML = song;
    }
    
};

const getCurrentSongTitle = (array) => {

  let song, title;
  [ ,song, title ] = Object.values(array[0]);
  const currentTrack = `${song} - ${title}`;

  return currentTrack;

}

const getFormatedData = (array) => {
  const data = [];
  array.forEach((track) => {
    const trackDatas = [...track.childNodes];
    const trackData = {};

    trackDatas.forEach((item) => {
      trackData[item.nodeName] = item.innerHTML;
    });

    data.push(trackData);
  });
  return data;
};

getSongTitle();

setInterval(() => {
  getSongTitle();
}, 5000);